import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import { Location } from '@angular/common';
import {AuthenticationService} from './authentication.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(private router: Router, private location: Location, private authenticationService: AuthenticationService) {}
  canActivate(): boolean {
    if (this.authenticationService.isAuthenticated()) {
      console.log(this.router);
      return true;
    }
    // console.log('nononono', location.pathname.includes('fog'), location.pathname);
    // if (location.pathname.includes('fog')) {
    //   this.router.navigate(['/fog/login'], { replaceUrl: true });
    //   return true;
    // } else {
    //   this.router.navigate(['/login'], { replaceUrl: true });
    //   return true;
    // }
    this.router.navigate(['/login'], { replaceUrl: true });
    return true;

  }

  canActivateChild(): boolean {
    if (this.authenticationService.isAuthenticated()) {
      return true;
    }

    this.router.navigate(['/login'], { replaceUrl: true });
    return false;
  }
}
