import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable()
export class CommunicationService {

  private refreshMenu = new Subject<any>();
  private refreshHeader = new Subject<any>();
  private showPermissionError = new Subject<any>();
  private showError = new Subject<any>();



  sendRefreshMenu() {
    this.refreshMenu.next();
  }

  getRefreshMenu(): Observable<any> {
    return this.refreshMenu.asObservable();
  }


  sendShowPermissionError() {
    this.showPermissionError.next();
  }

  sendError(message: string) {
    this.showError.next(message);
  }

  getShowPermissionError() {
    return this.showPermissionError.asObservable();
  }

  getError() {
    return this.showError.asObservable();
  }

  sendRefreshHeader() {
    this.refreshHeader.next();
  }

  getRefreshHeader() {
    return this.refreshHeader.asObservable();
  }

}
