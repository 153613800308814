import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../http/data.service';
import { HttpClient } from '@angular/common/http';


const tokenKey = 'Token';
const rolesKey = 'Roles';
const userDataKey = 'User';
const permissionsKey = 'Permissions';
const viewsKey = 'Views';

@Injectable()
export class AuthenticationService extends DataService {

  constructor(private router: Router,
              private toastr: ToastrService,
              private location: Location,
              http: HttpClient) {
    super(http);
  }


  /**
   * @returns {boolean}
   */
  isAuthenticated(): boolean {
    console.log('service', location.pathname.includes('fog'), location);
    if (location.pathname.includes('fog')) {
      if (location.pathname.includes('login')) {
        return true;
      } else {
        const isToken = localStorage.getItem('sysur');
        return !!(isToken);
      }
    } else {
      const isToken = localStorage.getItem(tokenKey);
      return !!(isToken);
    }
  }

  setUser(authResponse: any) {
    let user = {};
    if (authResponse.type_id === 1) {
      user = {
        name: authResponse.name,
        id: authResponse.id,
        companyid: authResponse.company[0].id,
        companyname: authResponse.company[0].name,
        type: authResponse.type_id,
      };
    } else {
      user = {
        name: authResponse.name,
        id: authResponse.id,
        companyid: 0,
        companyname: 'NEDAERO',
        type: authResponse.type_id,
      };
    }
    const roles = [...authResponse.roles];
    const permissions = [...authResponse.roles];
    const viewss = [...authResponse.views];
    this.setRoles(roles);
    this.setViews(viewss);
    this.setPermissions(permissions);
    this.setLoggedInUser(user);
  }

  setTokenAndUser(loginResponse: any) {
    const token = loginResponse.access_token;
    this.setAuthToken(token);
  }

  setAuthToken(tokenData?: string) {
    if (!tokenData || _.isNull(tokenData) || _.isUndefined(tokenData)) {
      localStorage.removeItem(tokenKey);
    } else {
      localStorage.setItem(tokenKey, tokenData);
    }
  }

  setLoggedInUser(userData?: Object) {

    if (!userData || _.isNull(userData) || _.isUndefined(userData)) {
      localStorage.removeItem(userDataKey);
    } else {
      localStorage.setItem(userDataKey, JSON.stringify(userData));
    }
  }

  setRoles(roles: any[] = []) {
    if (roles.length) {
      localStorage.setItem(rolesKey, JSON.stringify(roles));
    } else {
      localStorage.removeItem(rolesKey);
    }
  }

  setPermissions(permissions: any[] = []) {
    if (permissions.length) {
      const permission = [];
      permissions.forEach( obj => {
        permission.push(obj.permissions);
      });
      localStorage.setItem(permissionsKey, JSON.stringify(permission));
    } else {
      localStorage.removeItem(permissionsKey);
    }
  }

  setViews(views: any[] = []) {
    if (views.length) {
      localStorage.setItem(viewsKey, JSON.stringify(views));
    } else {
      localStorage.removeItem(viewsKey);
    }
  }

  logOut() {
    this.GetAll('logout')
      .subscribe((response: any) => {
        this.setAuthToken();
        this.setLoggedInUser();
        this.setRoles();
        this.setPermissions();
        this.toastr.warning('You sign out successfully');
        this.router.navigate(['login'], { replaceUrl: true });
      });

  }
}
