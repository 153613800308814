import { NgModule } from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {Shell} from './shell/shell.service';
import {FogShell} from './fogshell/fogshell.service';

const routes: Routes = [
  Shell.childRoutes([{ path: 'portal', loadChildren: 'src/app/master/master.module#MasterModule' }]),
  FogShell.childRoutes([{ path: 'fog', loadChildren: 'src/app/fogmaster/fogmaster.module#FogMasterModule' }]),
  { path: '**', redirectTo: 'login', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
