import {Component, OnChanges, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SystemUsersService} from '../../../master/modules/system-users/system-users.service';
import {PermissionGroupsService} from '../../../master/modules/permission-groups/permission-groups.service';
import {forEach} from '@angular/router/src/utils/collection';
declare var $: any;
@Component({
  selector: 'app-edit-system-user',
  templateUrl: './edit-system-user.component.html',
  styles: []
})
export class EditSystemUserComponent implements OnInit {

  systemUserForm: FormGroup = this.formBuilder.group({
    id: null,
    name: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(10)]],
    roles: [null, Validators.required],
    departments: [null, Validators.required],
    agent_code: [null]
  });

  public edit = false;
  public submitted = false;
  public rolesList: any[] = [];
  public departmentList: any[] = [];

  constructor(private formBuilder: FormBuilder,
              private systemUserService: SystemUsersService,
              private rolesService: PermissionGroupsService) {}

  ngOnInit() {
    this.systemUserService.editSystemUserModal.subscribe((response) => {
      if (response.edit) {
        this.edit = true;
        this.getSystemUser(response.id);
      } else {
        this.resetForm();
        this.edit = false;
      }
    });
    this.rolesService.loadPermissionGroups().subscribe((response) => {
      this.rolesList = response.roles;
    });
    this.systemUserService.loadAllDepartments().subscribe((response) => {
      this.departmentList = response.departments;
    });
  }
  private getSystemUser(id: any) {
    this.systemUserService.getSystemUser(id).subscribe((response) => {
      const formData = {};
      const roles = [];
      const departments = [];
      formData['id'] = response.user[0]['id'];
      formData['name'] = response.user[0]['name'];
      formData['email'] = response.user[0]['email'];
      if (response.user[0]['roles'].length > 0) {
        for (const role of response.user[0]['roles']) {
          roles.push(role.id);
        }
      }
      formData['agent_code'] = response.user[0]['agent_code'];
      if (response.user[0]['departments'].length > 0) {
        for (const department of response.user[0]['departments']) {
          departments.push(department.id);
        }
      }
      formData['roles'] = roles;
      formData['departments'] = departments;
      this.systemUserForm.controls['password'].setErrors({'incorrect': false});
      this.systemUserForm.controls['password'].setValue('ljksf');
      this.systemUserForm.patchValue(formData);

    });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.systemUserForm.invalid) {
      return;
    } else {

      const systemUserFormData = Object.assign({}, this.systemUserForm.value);
      systemUserFormData.type_id = 2;

      if (this.edit) {
        delete systemUserFormData.password;
        this.systemUserService.updatetSystemUser(systemUserFormData).subscribe((response) => {
          this.submitted = false;
          this.systemUserService.reloadSystemUsersMethod();
        });
      } else {
        delete systemUserFormData.id;
        this.systemUserService.createSystemUser(systemUserFormData).subscribe((response) => {
          this.submitted = false;
          this.systemUserService.reloadSystemUsersMethod();
        });
      }


      $('#editUser').foundation('close');
    }
  }
  get f() { return this.systemUserForm.controls; }

  public resetForm() {
    this.systemUserForm = this.formBuilder.group({
      id: null,
      name: ['', [Validators.required]],
      email: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(10)]],
      roles: [null, Validators.required],
      departments: [null, Validators.required],
      agent_code: [null]
    });
  }

  public generatePassword() {
    const randomstring = Math.random().toString(36).slice(-8);
    this.systemUserForm.patchValue({
      password: randomstring
    });
  }

}
